import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@material-ui/core'
import { StyledModal, AnimatedSuccessTick, AnimatedErrorCross } from 'frame/components'
import moment from 'moment'
import { updateServiceHistoryReliableFlag, fetch, reset } from '../../../actions'
import { useEnabledFeatureToggleList } from 'features/settings'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useProfile } from 'features/account'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 350,
  },
  heading: {
    minWidth: 150,
  },
  cell: {
    verticalAlign: 'top',
  },
  cross: {
    width: 22,
    height: 22,
    margin: '0px 2px',
    color: '#CCCCCC',
    cursor: 'initial',
  },
  crossRed: {
    width: 22,
    height: 22,
    margin: '0px 2px',
    color: '#EB0A1E',
    cursor: 'initial',
  },
  tick: {
    width: 22,
    height: 22,
    margin: '0px 2px',
    color: '#CCCCCC',
    cursor: 'initial',
  },
  greenTick:{
    width: 22,
    height: 22,
    margin: '0px 2px',
    color: '#58C021',
    cursor: 'initial',
  },
  isReliableGrid:{
    justifyContent: 'center',
  },
  isNotReliable:{
    backgroundColor: "#FDE2E5",
    "&:hover, &:focus": {
      backgroundColor: "#FDE2E5 !important" 
    }
  }
})

const ServiceHistoryTable = ({ data, onUpdateServiceHistoryReliableFlag, updateOdoData, agreementNo, fleetFlags, onResetFlags }) => {
  const classes = useStyles()
  const user = useProfile();
  
  const featureToggleList = useEnabledFeatureToggleList()
  const kmExceptionsFeatureToggle = featureToggleList.includes('KMExceptions')

  const [serviceHistory, setServiceHistory] = useState([])
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  useEffect(() => { 
    if(data && JSON.stringify(data) !== JSON.stringify(serviceHistory)) {
      setServiceHistory([...data])
      let lastReading = data.filter(function (e) {
        return e.isReliable;
      });
      if (lastReading.length>0){
        updateOdoData(lastReading[0].serviceDate,lastReading[0].serviceOdo)
      }else{
        updateOdoData("","")
      }
    }
    
  }, [data,serviceHistory]) // eslint-disable-line
  useEffect(() => { 
    if(fleetFlags.fetchServiceHistory === "error") {
      setShowErrorModal(true);
    }
  }, [fleetFlags.fetchServiceHistory])

  const onCrossClick = (item) => {
    // const updatedItem = {...item}
    // updatedItem.isReliable = false;
    // updatedItem["userUpdated"] = user.email;
    // updatedItem["dateUpdated"] = moment();
    // onUpdateServiceHistoryReliableFlag(updatedItem);
  }
  const onTickClick = (item) => {
    // const updatedItem = {...item}
    // updatedItem.isReliable = true;
    // updatedItem["userUpdated"] = user.email;
    // updatedItem["dateUpdated"] = moment();
    // onUpdateServiceHistoryReliableFlag(updatedItem);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="service history table">

          <TableHead>
            <TableRow>
              {kmExceptionsFeatureToggle ? ['Date', 'Odo Reading', 'Source', 'Dealership', 'Reliable Odo?'].map((heading, index) => (
                <TableCell key={`table-heading-${index}`} align="center" className={classes.heading}>
                  <strong>{heading}</strong>
                </TableCell>
              ))
              : ['Date', 'Odo Reading', 'Source', 'Dealership'].map((heading, index) => (
                <TableCell key={`table-heading-${index}`} align="center" className={classes.heading}>
                  <strong>{heading}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {serviceHistory && serviceHistory.length > 0 ? serviceHistory.map(({ serviceDate, serviceOdo, serviceType, dealership, isReliable }, rowIndex) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`table-row-${rowIndex}`} className={kmExceptionsFeatureToggle && isReliable!=null && !isReliable ? classes.isNotReliable : null}>
                  <TableCell key={`table-serviceDate-column-${rowIndex}`} align="center" className={classes.cell}>
                    {serviceDate ? moment(serviceDate).format('DD/MM/YYYY') : '-'}
                  </TableCell>
                  <TableCell key={`table-serviceOdo-column-${rowIndex}`} align="center" className={classes.cell}>
                    {serviceOdo ? serviceOdo.toLocaleString('en-NZ') : '-'}
                  </TableCell>
                  <TableCell key={`table-serviceType-column-${rowIndex}`} align="center" className={classes.cell}>
                    {serviceType}
                  </TableCell>
                  <TableCell key={`table-dealership-column-${rowIndex}`} align="center" className={classes.cell}>
                    {dealership}
                  </TableCell>
                  {kmExceptionsFeatureToggle ? (
                    <TableCell key={`table-isReliable-column-${rowIndex}`} align="center" className={classes.cell}>
                      <Grid container className={classes.isReliableGrid}>
                        { !isReliable ? (
                        <Grid item>
                          <CancelIcon className={isReliable!=null && !isReliable ? classes.crossRed : classes.cross} onClick={() => onCrossClick(serviceHistory[rowIndex])} />
                        </Grid>
                        ) : (
                        <Grid item>
                          <CheckCircleIcon className={isReliable ? classes.greenTick : classes.tick}  onClick={() => onTickClick(serviceHistory[rowIndex])} />  
                        </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  ):null}
                </TableRow>
              )
            }) : (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  No vehicle history available.
                </TableCell>
              </TableRow>
            )}
            
          </TableBody>

        </Table>
      </TableContainer>
      <StyledModal
        open={showSuccessModal || showErrorModal}
        onCancel={() => {
          setShowErrorModal(false)
          setShowSuccessModal(false)
          onResetFlags()
        }}
        options={['noButtons']}
        animatedIcon={showSuccessModal ? <AnimatedSuccessTick size={50} /> : <AnimatedErrorCross size={50} />}
      >
        You cannot update this record from the portal. Please contact your TFM account manager
      </StyledModal>
    </Paper>
  )
}

ServiceHistoryTable.propTypes = {
  data: PropTypes.array.isRequired,
  onUpdateServiceHistoryReliableFlag: PropTypes.func.isRequired,
  fleetFlags: PropTypes.object.isRequired,
}

const mapStateToProps = ({ fleet }) => ({
  data: fleet.relatedVehicleHistory,
  fleetFlags: fleet.flags
})
const mapDispatchToProps = (dispatch) => ({
  onUpdateServiceHistoryReliableFlag: (payload) => {
    dispatch(updateServiceHistoryReliableFlag(payload))
  },
  onResetFlags: () => {
    dispatch(reset())
  },
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(',')
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
})


export default connect(mapStateToProps,mapDispatchToProps)(ServiceHistoryTable)

